<template>
  <div>
    <button
      :class="Skin"
      @click="goSectionEmit"
    >
      {{ buttonTitle }}</button>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    title: { type: String, default: '' },
    skin: { type: String, default: '' },
  },
  setup(props) {
    const state = reactive({
      buttonTitle: props.title,
      Skin: props.skin,
    })

    return toRefs(state)
  },
  methods: {
    goSectionEmit() {
      this.$emit('click')
    },
  },
}
</script>
