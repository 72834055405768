<template>
  <div class="e-content">
    <div id="ComponentCharacteristics">
      <h1>Button</h1>
      <p>버튼 컴포넌트는 여러 페이지에서 사용되며 전달받는 props 속성에 따라 결정됩니다.
      </p>
      <b-card title="Component Characteristics">

        <p class="mb-2">컴포넌트로 전달 받는 속성은 다음과 같습니다.</p>
        <p class="mb-1"><span class="font-weight-bold">title:</span> 버튼에 들어갈 텍스트입니다.<br>
          <span class="font-weight-bold">skin:</span> 버튼에 디자인되는 스킨입니다.
        </p>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="props"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">Vue의 모든 컴포넌트 인스턴스에는 각자의 자체 격리된 범위(scope)가 있습니다. 이 때문에 하위 컴포넌트의 템플릿에서는 상위 데이터를 직접 참조할
            수 없는데, props 를 사용하면 하위 컴포넌트로 데이터를 전달할 수 있습니다.
            <br>
            <h5 class="my-2">사용 예시</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codePropsSample }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="emit Event"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">props와 반대로 자식 컴포넌트에서 부모에게 데이터를 보낼 때 사용됩니다. emit 옵션은 부모 컴포넌트가 자식 컴포넌트에서 만들어진 사용자지정
            이벤트를 받아(v-on) 특정 동작을 수행할 수 있습니다.
            <br>
            <h5 class="my-2">규칙은 다음과 같습니다</h5>
            1. 전달 키와 받는 키가 동일해야 한다.<br>
            2. 받는 키를 등록해줘야 합니다.
            <br>
            <h5 class="my-2">사용 방법</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codeEmitSample }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="Composition API"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">Vue의 기존 option API는 컴포넌트가 커지면서 수많은 데이터가 공존했다면 methods, computed도 그만큼 많아지고, data가 어디서
            어떤 함수에 의해 변하는지 분산되어 추적이 어려워 집니다. 그에 따라 유지보수가 어려워집니다.
            composition API를 사용함으로 setup 함수에 데이터가 그룹핑 되어 보다 용이하게 데이터의 흐름을 파악하고 유지보수와 재사용하기가 용이합니다.
            <br><br>
            <code>setup</code> 함수에 data, method, lifecycle hooks 등을 등록하여 리액트의 훅과 같이 사용할 수 있습니다. <span class="font-weight-bold">props</span>와 <span class="font-weight-bold">state</span>로 데이터를 관리합니다.
            <h5 class="my-2">사용 예시</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codeCompositionAPI }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>
      </b-card>

      <b-card-code id="ButtonHome" title="Button-Home">
        <p>홈 첫 번째 섹션에서 사용되는 버튼입니다. 홈의 문의 섹션으로 이동할 시 클릭합니다.</p>
        <div class="mb-3 d-flex justify-content-center">
        <e-button
            title="Contact Us"
            skin="home-btn"
        />
        </div>
        <b-card-text>
          <span class="font-weight-bold">props:</span> title="Contact Us", skin="home-btn"
        </b-card-text>
        <template #code>
          {{ codeButtonFirstSectionComponent }}
        </template>
      </b-card-code>

      <b-card-code id="ButtonSendMsg" title="Button-Send-Msg">
        <p>홈 다섯 번째 섹션에서 사용되는 버튼입니다. 문의 내용을 텔레그램에 전송 시 클릭합니다.</p>
        <div class="mb-3 d-flex justify-content-center">
          <e-button
              title="Send Message"
              skin="sendMsg-btn"
          />
        </div>
        <b-card-text>
          <span class="font-weight-bold">props:</span> title="Send Message", skin="sendMsg-btn"
        </b-card-text>
        <template #code>
          {{ codeButtonFifthSectionComponent }}
        </template>
      </b-card-code>

      <b-card-code id="ButtonContact" title="Button-Contact">
        <p>어바웃 페이지에서 사용되는 버튼입니다. 홈의 문의 섹션으로 이동할 시 클릭합니다.</p>
        <div class="mb-3 d-flex justify-content-center">
          <e-button
              title="Contact Us"
              skin="contact-btn"
          />
        </div>
        <b-card-text>
          <span class="font-weight-bold">props:</span> title="Contact Us", skin="home-btn"
        </b-card-text>
        <template #code>
          {{ codeButtonAboutComponent }}
        </template>
      </b-card-code>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  codeButtonAboutComponent,
  codeButtonFifthSectionComponent,
  codeButtonFirstSectionComponent,
  codeCompositionAPI,
  codeEmitSample,
  codePropsSample,

} from './code'
import EButton from '@/components/Button/Button.vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    EButton,
    BCardCode,
    BCard,
    BCardText,
    BImg,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['ComponentCharacteristics', 'ButtonHome', 'ButtonSendMsg', 'ButtonContact' ],
      codePropsSample,
      codeEmitSample,
      codeCompositionAPI,
      codeButtonFirstSectionComponent,
      codeButtonFifthSectionComponent,
      codeButtonAboutComponent,
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

<style>
.enter-img {
  width: 100%;
  min-width: 1100px
}
@media (max-width: 1780px) {
  .enter-img {
    width: 100%;
    min-width: 800px
  }
}
@media (max-width: 1476px) {
  .enter-img {
    width: 100%;
    min-width: 600px
  }
}

@media (max-width: 1276px) {
  .enter-img {
    width: 100%;
    min-width: 400px
  }
}

@media (max-width: 493px) {
  .enter-img {
    width: 100%;
    min-width: 100px
  }
}
</style>